import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import {
  useGlobals,
  useFocusTrap,
  useOnClickOutside,
  useDisableScroll,
} from '@bwoty-web/ui-kit/hooks';
import { Icon, TextInput } from '@bwoty-web/ui-kit';
import { UI_CROSS, UI_SEARCH } from '@bwoty-web/ui-kit/icons';
import cx from 'classnames';

import './globeSearch.scss';

const GlobeSearch = ({ isOpen }) => {
  const [showSearch, setShowSearch] = useState(isOpen);
  const [searchTerm, setSearchTerm] = useState('');
  const overlayRef = useRef(null);

  const { content } = useGlobals();

  useEffect(() => {
  // topmost header container
  const parentHeaderContainer = document.getElementById('globe-header-top-container');
  
    if (!showSearch) {
      parentHeaderContainer?.classList.remove('header-top-container--invisible');
      return undefined
    };

    parentHeaderContainer?.classList.add('header-top-container--invisible');

    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') setShowSearch(false);
    };

    window.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
      parentHeaderContainer?.classList.remove('header-top-container--invisible');
    };
  }, [showSearch]);

  useDisableScroll(showSearch);
  useFocusTrap(overlayRef, showSearch);
  useOnClickOutside(overlayRef, () => setShowSearch(false), showSearch);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCancel = () => {
    setShowSearch(false);
    setSearchTerm('');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    if (searchTerm) searchParams.set('q', encodeURIComponent(searchTerm));
    // window.location.search = searchParams.toString();
    const newUrl = `${content?.searchLink?.href?.url}?${searchParams.toString()}`;
    window.location.href = newUrl;
  };

  return (
    <>
      <button
        type="button"
        id="header-search-button"
        className="header-search-button"
        aria-expanded={showSearch}
        onClick={() => setShowSearch(true)}
      >
        <Icon
          path={UI_SEARCH}
          size="s"
          className="header-search-icon"
          aria-hidden="true"
        />
        <span className="visually-hidden">
          {content?.searchLink?.href?.name}
        </span>
      </button>
      {showSearch && (
        <form
          ref={overlayRef}
          className={cx('header-search-box', {
            'header-search-box--active': showSearch,
          })}
          onSubmit={handleSubmit}
        >
          <button type="submit" className="header-search-box__submit">
            <Icon path={UI_SEARCH} aria-hidden="true" />
            <span className="visually-hidden">
              {content?.searchLabel || 'Search'}
            </span>
          </button>
          <div className="header-search-box__field">
            <TextInput
              type="search"
              className="header-search-box__field"
              placeholder={`${content?.searchPlaceholder || 'Search'}`}
              value={searchTerm}
              onChange={handleChange}
              autoFocus={showSearch}
            />
          </div>
          <button
            type="button"
            onClick={handleCancel}
            className="header-search-box__back-button"
            aria-hidden="true"
          >
            <Icon path={UI_CROSS} aria-hidden="true" />
            <span className="visually-hidden">
              {content?.cancelLabel || 'Back'}
            </span>
          </button>
        </form>
      )}
    </>
  );
};

GlobeSearch.propTypes = {
  isOpen: PropTypes.bool,
};

GlobeSearch.defaultProps = {
  isOpen: false,
};

export default GlobeSearch;
