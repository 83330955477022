/* eslint-disable no-undef */
import { render } from 'react-dom';
import { Globals } from '@bwoty-web/ui-kit';
import GlobeSearch from '../shared/header/components/GlobeSearch';

const initialStateHeader = window.__INITIAL_STATE_HEADER__;
// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = `${initialStateHeader?.baseUrl}/static/`;

// comment out delete, state is shared with menu
// delete window.__INITIAL_STATE_HEADER__;

const searchRoot = document.getElementById('header-search');

if (searchRoot) {
  render(
    <Globals {...initialStateHeader}>
      <GlobeSearch isOpen={!!globeSearchIsLoadedByClick} />
    </Globals>,
    searchRoot,
  );
}
